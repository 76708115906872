var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"card-title-desc"}),_c('b-overlay',{attrs:{"show":_vm.overlayFlag,"spinner-variant":this.$overlayVariant,"spinner-type":this.$overlayType,"rounded":this.$overlayRounded}},[_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-sm-6"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"groupId"}},[_vm._v("กลุ่มอะไหล่")]),_c('multiselect',{class:{
                        'is-invalid': _vm.submitform && _vm.$v.form.groupId.$error,
                      },attrs:{"id":"groupId","options":_vm.optionsGroupId,"label":"nameTh","track-by":"nameTh","show-labels":false},model:{value:(_vm.form.groupId),callback:function ($$v) {_vm.$set(_vm.form, "groupId", $$v)},expression:"form.groupId"}}),(_vm.submitform && _vm.$v.form.groupId.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.groupId.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"partCode"}},[_c('code',[_vm._v("*")]),_vm._v(" รหัสอะไหล่")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.partCode),expression:"form.partCode"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitform && _vm.$v.form.partCode.$error,
                      },attrs:{"id":"partCode","disabled":"","type":"text","value":"Otto"},domProps:{"value":(_vm.form.partCode)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "partCode", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.partCode.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.partCode.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"nameTh"}},[_vm._v("ชื่ออะไหล่ (ภาษาไทย)")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nameTh),expression:"form.nameTh"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.form.nameTh.$error,
                        },attrs:{"id":"nameTh","type":"text","placeholder":"ชื่ออะไหล่ (ภาษาไทย)"},domProps:{"value":(_vm.form.nameTh)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nameTh", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.nameTh.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.nameTh.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"nameEn"}},[_vm._v("ชื่ออะไหล่ (ภาษาอังกฤษ)")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nameEn),expression:"form.nameEn"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.form.nameEn.$error,
                        },attrs:{"id":"nameEn","type":"text","placeholder":"ชื่ออะไหล่ (ภาษาอังกฤษ)"},domProps:{"value":(_vm.form.nameEn)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nameEn", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.nameEn.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.nameEn.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"year"}},[_vm._v("ปี")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.year),expression:"form.year"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitform && _vm.$v.form.year.$error,
                      },attrs:{"size":"4","id":"year","type":"text"},domProps:{"value":(_vm.form.year)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "year", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.year.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.year.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"costPrice"}},[_vm._v("ต้นทุน")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.costPrice),expression:"form.costPrice"}],staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitform && _vm.$v.form.costPrice.$error,
                        },attrs:{"min":1,"id":"costPrice","type":"text","placeholder":"","aria-describedby":"validationTooltipUsernamePrepend"},domProps:{"value":(_vm.form.costPrice)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "costPrice", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.costPrice.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.costPrice.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"sellPrice"}},[_vm._v("ราคาขาย/หน่วย (1)")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sellPrice),expression:"form.sellPrice"}],staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitform && _vm.$v.form.sellPrice.$error,
                        },attrs:{"min":1,"id":"sellPrice","type":"text","placeholder":"","aria-describedby":"validationTooltipUsernamePrepend"},domProps:{"value":(_vm.form.sellPrice)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "sellPrice", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.sellPrice.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.sellPrice.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"sellPrice2"}},[_vm._v("ราคาขาย/หน่วย (2)")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sellPrice2),expression:"form.sellPrice2"}],staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitform && _vm.$v.form.sellPrice2.$error,
                        },attrs:{"min":1,"id":"sellPrice2","type":"text","placeholder":"","aria-describedby":"validationTooltipUsernamePrepend"},domProps:{"value":(_vm.form.sellPrice2)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "sellPrice2", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.sellPrice2.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.sellPrice2.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"sellPrice3"}},[_vm._v("ราคาขาย/หน่วย (3)")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sellPrice3),expression:"form.sellPrice3"}],staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitform && _vm.$v.form.sellPrice3.$error,
                        },attrs:{"min":1,"id":"sellPrice3","type":"text","placeholder":"","aria-describedby":"validationTooltipUser\n                      \n                      Prepend"},domProps:{"value":(_vm.form.sellPrice3)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "sellPrice3", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.sellPrice3.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.sellPrice3.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"maxDcPer"}},[_vm._v("ส่วนลดสูงสุด(%)")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.maxDcPer),expression:"form.maxDcPer"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.form.maxDcPer.$error,
                        },attrs:{"min":1,"id":"maxDcPer","type":"text","placeholder":""},domProps:{"value":(_vm.form.maxDcPer)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "maxDcPer", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.maxDcPer.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.maxDcPer.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"maxDcAmount"}},[_vm._v("ส่วนลดสูงสุด(บาท)")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.maxDcAmount),expression:"form.maxDcAmount"}],staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.submitform && _vm.$v.form.maxDcAmount.$error,
                        },attrs:{"min":1,"id":"maxDcAmount","type":"text","placeholder":""},domProps:{"value":(_vm.form.maxDcAmount)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "maxDcAmount", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.maxDcAmount.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.maxDcAmount.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"stockMax"}},[_vm._v("Max")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.stockMax),expression:"form.stockMax"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.form.stockMax.$error,
                        },attrs:{"min":1,"id":"stockMax","type":"text","placeholder":""},domProps:{"value":(_vm.form.stockMax)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "stockMax", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.stockMax.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.stockMax.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"stockMin"}},[_vm._v("Min")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.stockMin),expression:"form.stockMin"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.form.stockMin.$error,
                        },attrs:{"min":1,"id":"stockMin","type":"text","placeholder":""},domProps:{"value":(_vm.form.stockMin)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "stockMin", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.stockMin.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.stockMin.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-md-2 col-sm-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"unitId"}},[_vm._v("หน่วยซื้อ/หน่วยขาย")]),_c('multiselect',{class:{
                        'is-invalid': _vm.submitform && _vm.$v.form.unitId.$error,
                      },attrs:{"id":"unitId","options":_vm.optionsUnits,"label":"nameTh","track-by":"nameTh","show-labels":false},model:{value:(_vm.form.unitId),callback:function ($$v) {_vm.$set(_vm.form, "unitId", $$v)},expression:"form.unitId"}}),(_vm.submitform && _vm.$v.form.unitId.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.unitId.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()],1)])]),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md"},[_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"value":"1","unchecked-value":"0","checked":"","plain":""},model:{value:(_vm.form.useInBp),callback:function ($$v) {_vm.$set(_vm.form, "useInBp", $$v)},expression:"form.useInBp"}},[_vm._v("ใช้กับศูนย์ซ่อมสีและตัวถัง")]),_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"value":"1","unchecked-value":"0","checked":"","plain":""},model:{value:(_vm.form.useInGs),callback:function ($$v) {_vm.$set(_vm.form, "useInGs", $$v)},expression:"form.useInGs"}},[_vm._v("ใช้กับศูนย์บริการ")])],1)]),_c('div',{staticClass:"col-md"},[_c('button',{staticClass:"btn btn-success float-end",attrs:{"type":"submit"}},[_vm._v(" บันทึก ")])])])])],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }