<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>
            <!-- skeleton  -->

            <b-overlay
              :show="overlayFlag"
              :spinner-variant="this.$overlayVariant"
              :spinner-type="this.$overlayType"
              :rounded="this.$overlayRounded"
            >
              <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                  <div class="col-md-4 col-sm-6">
                    <div class="mb-3 position-relative">
                      <label for="groupId">กลุ่มอะไหล่</label>
                      <multiselect
                        id="groupId"
                        v-model="form.groupId"
                        :options="optionsGroupId"
                        class=""
                        :class="{
                          'is-invalid': submitform && $v.form.groupId.$error,
                        }"
                        label="nameTh"
                        track-by="nameTh"
                        :show-labels="false"
                      >
                      </multiselect>

                      <div
                        v-if="submitform && $v.form.groupId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.groupId.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <div class="mb-3 position-relative">
                      <label for="partCode"> <code>*</code> รหัสอะไหล่</label>

                      <input
                        id="partCode"
                        disabled
                        v-model="form.partCode"
                        type="text"
                        class="form-control"
                        value="Otto"
                        :class="{
                          'is-invalid': submitform && $v.form.partCode.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.partCode.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.partCode.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <div class="mb-3 position-relative">
                      <label for="nameTh">ชื่ออะไหล่ (ภาษาไทย)</label>
                      <div class="input-group">
                        <input
                          id="nameTh"
                          v-model="form.nameTh"
                          type="text"
                          class="form-control"
                          placeholder="ชื่ออะไหล่ (ภาษาไทย)"
                          :class="{
                            'is-invalid': submitform && $v.form.nameTh.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.nameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.nameTh.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <div class="mb-3 position-relative">
                      <label for="nameEn">ชื่ออะไหล่ (ภาษาอังกฤษ)</label>
                      <div class="input-group">
                        <input
                          id="nameEn"
                          v-model="form.nameEn"
                          type="text"
                          class="form-control"
                          placeholder="ชื่ออะไหล่ (ภาษาอังกฤษ)"
                          :class="{
                            'is-invalid': submitform && $v.form.nameEn.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.nameEn.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.nameEn.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-3">
                    <div class="mb-3 position-relative">
                      <label for="year">ปี</label>
                      <input
                        size="4"
                        id="year"
                        v-model="form.year"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': submitform && $v.form.year.$error,
                        }"
                      />

                      <div
                        v-if="submitform && $v.form.year.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.year.required">{{ message }}</span>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-md-4 col-sm-4">
                  <div class="mb-3 position-relative">
                    <label for="storage">ที่เก็บ</label>

                    <multiselect
                      id="storage"
                      v-model="form.storage"
                      :options="options"
                      class=""
                      :class="{
                        'is-invalid': submitform && $v.form.storage.$error,
                      }"
                    ></multiselect>
                    <div
                      v-if="submitform && $v.form.storage.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.form.storage.required">{{
                        message
                      }}</span>
                    </div>
                  </div>
                </div> -->

                  <div class="col-6 col-md-2 col-sm-3">
                    <div class="mb-3 position-relative">
                      <label for="costPrice">ต้นทุน</label>
                      <div class="input-group">
                        <input
                          :min="1"
                          id="costPrice"
                          v-model="form.costPrice"
                          type="text"
                          class="form-control"
                          placeholder=""
                          aria-describedby="validationTooltipUsernamePrepend"
                          :class="{
                            'is-invalid':
                              submitform && $v.form.costPrice.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.costPrice.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.costPrice.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-2 col-sm-3">
                    <div class="mb-3 position-relative">
                      <label for="sellPrice">ราคาขาย/หน่วย (1)</label>
                      <div class="input-group">
                        <input
                          :min="1"
                          id="sellPrice"
                          v-model="form.sellPrice"
                          type="text"
                          class="form-control"
                          placeholder=""
                          aria-describedby="validationTooltipUsernamePrepend"
                          :class="{
                            'is-invalid':
                              submitform && $v.form.sellPrice.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.sellPrice.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.sellPrice.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-2 col-sm-3">
                    <div class="mb-3 position-relative">
                      <label for="sellPrice2">ราคาขาย/หน่วย (2)</label>
                      <div class="input-group">
                        <input
                          :min="1"
                          id="sellPrice2"
                          v-model="form.sellPrice2"
                          type="text"
                          class="form-control"
                          placeholder=""
                          aria-describedby="validationTooltipUsernamePrepend"
                          :class="{
                            'is-invalid':
                              submitform && $v.form.sellPrice2.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.sellPrice2.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.sellPrice2.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-2 col-sm-3">
                    <div class="mb-3 position-relative">
                      <label for="sellPrice3">ราคาขาย/หน่วย (3)</label>
                      <div class="input-group">
                        <input
                          :min="1"
                          id="sellPrice3"
                          v-model="form.sellPrice3"
                          type="text"
                          class="form-control"
                          placeholder=""
                          aria-describedby="validationTooltipUser
                        
                        Prepend"
                          :class="{
                            'is-invalid':
                              submitform && $v.form.sellPrice3.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.sellPrice3.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.sellPrice3.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 col-md-2 col-sm-3">
                    <div class="mb-3 position-relative">
                      <label for="maxDcPer">ส่วนลดสูงสุด(%)</label>
                      <div class="input-group">
                        <input
                          :min="1"
                          id="maxDcPer"
                          v-model="form.maxDcPer"
                          type="text"
                          class="form-control"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.form.maxDcPer.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.maxDcPer.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.maxDcPer.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-2 col-sm-3">
                    <div class="mb-3 position-relative">
                      <label for="maxDcAmount">ส่วนลดสูงสุด(บาท)</label>
                      <div class="input-group">
                        <input
                          :min="1"
                          id="maxDcAmount"
                          v-model="form.maxDcAmount"
                          type="text"
                          class="form-control"
                          placeholder=""
                          :class="{
                            'is-invalid':
                              submitform && $v.form.maxDcAmount.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.maxDcAmount.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.maxDcAmount.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-2 col-sm-3">
                    <div class="mb-3 position-relative">
                      <label for="stockMax">Max</label>
                      <div class="input-group">
                        <input
                          :min="1"
                          id="stockMax"
                          v-model="form.stockMax"
                          type="text"
                          class="form-control"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.form.stockMax.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.stockMax.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.stockMax.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-2 col-sm-3">
                    <div class="mb-3 position-relative">
                      <label for="stockMin">Min</label>
                      <div class="input-group">
                        <input
                          :min="1"
                          id="stockMin"
                          v-model="form.stockMin"
                          type="text"
                          class="form-control"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.form.stockMin.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.stockMin.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.stockMin.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label for="unitId">หน่วยซื้อ/หน่วยขาย</label>

                      <multiselect
                        id="unitId"
                        v-model="form.unitId"
                        :options="optionsUnits"
                        :class="{
                          'is-invalid': submitform && $v.form.unitId.$error,
                        }"
                        label="nameTh"
                        track-by="nameTh"
                        :show-labels="false"
                      ></multiselect>
                      <div
                        v-if="submitform && $v.form.unitId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.unitId.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md">
                    <b-form-checkbox
                      v-model="form.useInBp"
                      value="1"
                      unchecked-value="0"
                      class="mb-3"
                      checked
                      plain
                      >ใช้กับศูนย์ซ่อมสีและตัวถัง</b-form-checkbox
                    >
                    <b-form-checkbox
                      v-model="form.useInGs"
                      value="1"
                      unchecked-value="0"
                      class="mb-3"
                      checked
                      plain
                      >ใช้กับศูนย์บริการ</b-form-checkbox
                    >
                  </div>
                </div>

                <div class="col-md">
                  <button class="btn btn-success float-end" type="submit">
                    บันทึก
                  </button>
                </div>
              </form>
            </b-overlay>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>

    <!-- end col -->
  </Layout>
</template>
<script>
import {
  maxValue,
  minValue,
  // numeric
} from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import useNetw from "@useNetw";

/**
 * Form validation component
 */
export default {
  mounted() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },
  created() {
    this.getDataUpdate();
    this.getGroups();
    this.getUnits();
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  page: {
    title: "Inventory",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      loading: undefined,
      message: "โปรดระบุข้อมูล",
      title: "แก้ไขข้อมูลอะไหล่",
      items: [
        {
          text: "ข้อมูลอะไหล่",
          href: "/parts/spare-part",
        },
        {
          text: "แก้ไขข้อมูลอะไหล่",
          active: true,
        },
      ],
      partId: this.$route.params.partId, //ดึง id แก้ไข
      groupId: null,
      unitId: [],

      optionsGroupId: [],
      optionsUnits: [],
      options: [],
      opcodeType: [
        {
          name: "อะไหล่นอก",
          id: "EX",
        },
        {
          name: "กำหนดเอง",
          id: "CUSTOM",
        },
      ],
      form: {
        groupId: "",
        partCode: "",
        nameTh: "",
        nameEn: "",
        year: "",
        costPrice: "",
        sellPrice: "",
        sellPrice2: "",
        sellPrice3: "",
        unitId: "",
        maxDcPer: "",
        maxDcAmount: "",
        stockMin: "",
        stockMax: "",
        useInBp: "",
        useInGs: "",

        codeType: "",
      },

      submitform: false,
    };
  },

  validations: {
    form: {
      groupId: {},
      partCode: {},
      nameTh: {},
      nameEn: {},
      year: {},
      costPrice: {},
      sellPrice: {},
      sellPrice2: {},
      sellPrice3: {},
      unitId: {},
      maxDcPer: {
        maxValue: maxValue(100),
        minValue: minValue(0),
      },
      maxDcAmount: {},
      stockMin: {},
      stockMax: {},
      useInBp: {},
      useInGs: {},

      codeType: {},
    },
  },

  methods: {
    customLabel({ id, first_name }) {
      return `${id}  ${first_name}`;
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.form.$invalid != true) {
           this.updatePart();
      } 
    },

    getDataUpdate: function () {
      this.overlayFlag = true;
      // this.loading = true; //skeleton true
      useNetw
        .get("api/central/part/show", {
          params: {
            partId: this.partId,
          },
        })
        .then((response) => {
          this.rowPart = response.data.data;
          this.form.groupId = {
            groupId: response.data.data.groupId,
            nameTh: response.data.data.groupNameTh,
          };
          this.form.partCode = response.data.data.partCode;
          this.form.nameTh = response.data.data.nameTh;
          this.form.nameEn = response.data.data.nameEn;
          this.form.year = response.data.data.year;
          this.form.costPrice = response.data.data.costPrice;
          this.form.sellPrice = response.data.data.sellPrice;
          this.form.sellPrice2 = response.data.data.sellPrice2;
          this.form.sellPrice3 = response.data.data.sellPrice3;
          this.form.maxDcPer = response.data.data.maxDcPer;
          this.form.maxDcAmount = response.data.data.maxDcAmount;
          this.form.stockMin = response.data.data.stockMin;
          this.form.stockMax = response.data.data.stockMax;
          this.form.unitId = {
            unitId: response.data.data.unitId,
            nameTh: response.data.data.unitNameTh,
          };
          this.form.useInGs = response.data.data.useInGs;
          this.form.useInBp = response.data.data.useInBp;
          this.form.codeType = {
            name: response.data.data.codeType,
          };

          //
          // console.log(response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },

    updatePart: function () {
      // this.overlayFlag = true;
      this.form.costPrice = this.form.costPrice.toF;
      useNetw
        .put("api/part/update", {
          partId: this.partId,
          groupId:
            this.form.groupId.groupId != null ? this.form.groupId.groupId : "",
          nameTh: this.form.nameTh != null ? this.form.nameTh : "",
          nameEn: this.form.nameEn != null ? this.form.nameEn : "",
          year: this.form.year != null ? this.form.year : "",
          costPrice: this.form.costPrice != null ? this.form.costPrice : "",
          sellPrice: this.form.sellPrice != null ? this.form.sellPrice : "",
          sellPrice2: this.form.sellPrice2 != null ? this.form.sellPrice2 : "",
          sellPrice3: this.form.sellPrice3 != null ? this.form.sellPrice3 : "",
          unitId:
            this.form.unitId.unitId != null ? this.form.unitId.unitId : "",
          maxDcPer: this.form.maxDcPer != null ? this.form.maxDcPer : "",
          maxDcAmount:
            this.form.maxDcAmount != null ? this.form.maxDcAmount : "",
          stockMin: this.form.stockMin != null ? this.form.stockMin : "",
          stockMax: this.form.stockMax != null ? this.form.stockMax : "",
          useInBp: this.form.useInBp != null ? this.form.useInBp : "",
          useInGs: this.form.useInGs != null ? this.form.useInGs : "",
        })

        .then((response) => {
          // console.log(this.form.costPrice);
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "spare-part" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getGroups: function () {
      this.overlayFlag = true;
      // this.loading = true; //skeleton true
      useNetw
        .get("api/master/part/groups", {})
        .then((response) => {
          this.optionsGroupId = response.data.data;
          // console.log(this.optionsGroupId);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    getUnits: function () {
      this.overlayFlag = true;
      // this.loading = true; //skeleton true
      useNetw
        .get("api/master/part/units", {})
        .then((response) => {
          this.optionsUnits = response.data.data;
          // console.log(this.optionsUnits);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
  },
  middleware: "authentication",
};
</script>

<style lang="scss" scoped></style>
